import styled from 'styled-components';
import { mq } from 'stylesheets/utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  ${mq(576)} {
    margin: auto;
  }
`;
export const ImageWrapper = styled.div`
  min-width: 180px;
  max-width: 180px;
  width: 180px;
  min-height: 180px;
  max-height: 180px;
  height: 180px;
  position: relative;
`;
export const Title = styled.h3`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.brandColor};
  text-align: center;
  margin-bottom: 5px;
`;
export const Desc = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  max-width: 320px;
  margin-bottom: 15px;
`;

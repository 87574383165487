import Link from '@Components/common/Link';
import styled, { css } from 'styled-components';
import { mq, rgba } from 'stylesheets/utils';
export const Main = styled.div`
  padding: 40px 0 30px 0;
`;
export const LandingFiltersWrapper = styled.div`
  margin-top: 45px;
`;
export const TwoPartsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
  h4 {
    font-size: 3.6rem;
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    color: ${({ theme }) => theme.colors.black};
    margin-right: 100px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  ${mq(992)} {
    flex-wrap: wrap;
    justify-content: center;
    h4 {
      margin-right: 0;
    }
  }
  ${mq(576)} {
    h4 {
      text-align: center;
    }
  }
`;

export const TagList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
  gap: 10px;
`;
export const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardList = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 18px;
  width: 100%;
  overflow: hidden;
  min-height: 360px;
  ${mq(992)} {
    overflow-x: auto;
    justify-content: flex-start;
  }
  ${mq(576)} {
    gap: 10px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mq(576)} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Pagination = styled.ul`
  display: flex;
  margin: 0 5px;
  ${mq(576)} {
    margin-bottom: 16px;
  }
`;

export const PaginationNumber = styled.li<{
  readonly active?: boolean;
}>`
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.pagination.lightenGray};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  color: ${({ theme }) => theme.colors.pagination.lightGray};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  ${({ active }) =>
    active
      ? css`
          border-radius: 4px;
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.pagination.black};
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
        `
      : css``}
`;

export const PaginationArrow = styled.li<{
  readonly available?: boolean;
}>`
  width: 36px;
  height: 36px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => rgba(theme.colors.pagination.gray, 0.1)};
  border-radius: 4px;
  transform: scale(-1, 1) /*rtl: none */;
  ${({ available }) =>
    available
      ? css`
          background-color: ${({ theme }) => theme.colors.pagination.babyBlue};
        `
      : css``}
  ${mq(576)} {
    margin-bottom: 16px;
  }
`;

export const ShowMoreArrow = styled.div`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(-1, 1) /*rtl: none */;
  margin-left: 16px;
`;

export const ShowMore = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.cartCountColor};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  ${mq(576)} {
    margin-left: 30px;
  }
`;

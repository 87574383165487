import styled from 'styled-components';
import { mq } from 'stylesheets/utils';
export const StickyPhone = styled.a`
  width: 56px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 145px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.brandColor};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-wrap: nowrap;
  z-index: 10;
  transition: 0.3s ease all;
  overflow: hidden;
  &:hover {
    top: 140px;
    width: 146px;
    height: 70px;
  }
  ${mq(576)} {
    top: 80px;
  }
`;
export const Icon = styled.div`
  max-width: 56px;
  min-width: 56px;
  max-height: 52px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HotLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const P = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsizes.body12};
  line-height: 1;
`;
export const Number = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsizes.h5};
  line-height: 1;
`;

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import PhoneIcon from 'public/white-phone.svg';
import { HotLine, Icon, Number, P, StickyPhone } from './Sections.styled';
type Props = {};
export default function PhoneTag({}: Props) {
  const { t } = useTranslation('Landing');
  return (
    <StickyPhone href="tel:15145" target="_blank" rel="noreferrer noopener">
      <HotLine>
        <P>{t('hotline')}</P>
        <Number>15145</Number>
      </HotLine>
      <Icon>
        <Image priority={true} src={PhoneIcon} width={24} height={24} alt="phone icon" />
      </Icon>
    </StickyPhone>
  );
}

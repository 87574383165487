import RCPagination from 'rc-pagination';
import styled from 'styled-components';
export const StyledPagination = styled(RCPagination)`
  display: flex;
  li,
  button,
  a {
    display: flex;
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 1.4rem;
    font-weight: 600;
    @media (max-width: 375px) {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
    }
  }
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    overflow: hidden;
    svg {
      transform: scale(0.6);
    }
  }
  .rc-pagination-item:last-child {
    display: none !important;
  }
  .rc-pagination-item {
    background-color: ${({ theme }) => theme.colors.pagination.lightenGray};
    transition: 0.2s ease background-color, 0.2s ease box-shadow;
  }
  .rc-pagination-item-active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.white};
  }
  .rc-pagination-next {
    transform: scale(-1, 1) /*rtl: scale(1,1) */;
    margin-left: 5px;
    background-color: ${({ theme }) => theme.colors.pagination.babyBlue};
    transition: 0.2s ease background-color;
  }
  .rc-pagination-prev {
    transform: scale(1, 1) /*rtl: scale(-1,1) */;
    margin-right: 5px;
    background-color: ${({ theme }) => theme.colors.pagination.babyBlue};
    transition: 0.2s ease background-color;
  }
  .rc-pagination-disabled {
    background-color: ${({ theme }) => theme.colors.pagination.lightGray};
    cursor: not-allowed;
    svg path {
      fill: ${({ theme }) => theme.colors.pagination.gray};
    }
  }
`;

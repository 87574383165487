// eslint-disable-next-line import/no-anonymous-default-export
export default {
  items_per_page: '/ صفحة',
  jump_to: 'الذهاب إلى',
  jump_to_confirm: 'تأكيد',
  page: 'صفحة',
  prev_page: 'الصفحة السابقة',
  next_page: 'الصفحة القادمة',
  prev_5: 'خمس صفحات سابقة',
  next_5: 'خمس صفحات قادمة',
  prev_3: 'ثلاث صفحات سابقة',
  next_3: 'ثلاث صفحات قادمة',
  page_size: 'حجم الصفحة',
};

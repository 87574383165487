import Axios from 'lib/Axios';
import { APIResponse } from 'types/APIResponse';
import { DefaultPagination } from 'types/common';
import { MissingProductOrder, MissingProductsFormValues } from 'types/MissingProducts';
import { Product, ProductParams } from 'types/Products';
import { ProductRateParams, Rate } from 'types/Rate';
import { ProductReviewParams, Review } from 'types/Review';

export const _GetProducts = (params?: ProductParams) =>
  Axios.get<APIResponse<Product[]>>('/all-products', {
    params,
  }).then((res) => res.data);

export const _GetProductDetails = (id: string, params?: ProductParams) =>
  Axios.get<Product>(`/all-products/${id}`, {
    params,
  }).then((res) => res.data);

export const _GetProductReviews = (params: ProductReviewParams) =>
  Axios.get<APIResponse<Review[]>>(`/products/review`, {
    params,
  }).then((res) => res.data);

export const _GetProductRate = (id: string, params?: ProductRateParams) =>
  Axios.get<Rate>(`/products/rating/${id}`, {
    params,
  }).then((res) => res.data);

export const _GetMissingProduct = (params?: DefaultPagination) =>
  Axios.get<APIResponse<MissingProductOrder[]>>('/products/missing-products', {
    params,
  }).then((res) => res.data);

export const _GetMissingProductDetails = (missingOrderId: string | number) =>
  Axios.get<MissingProductOrder>(`/products/missing-products/${missingOrderId}`).then((res) => res.data);

export const _AddMissingProduct = (data: MissingProductsFormValues) =>
  Axios.post<MissingProductOrder>('/products/missing-products', data).then((res) => res.data);

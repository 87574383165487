import { useRouter } from 'next/router';
import { PaginationProps } from 'rc-pagination';
import React from 'react';
import ar from './locale_ar';
import en from './locale_en';
import { StyledPagination } from './Pagination.styled';

type Props = {};
const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.154" height="17.76" viewBox="0 0 10.154 17.76">
    <path
      id="Icon_ionic-ios-arrow-forward"
      data-name="Icon ionic-ios-arrow-forward"
      d="M3.061,8.877,9.782,2.161a1.264,1.264,0,0,0,0-1.792,1.28,1.28,0,0,0-1.8,0L.37,7.978a1.267,1.267,0,0,0-.037,1.75L7.979,17.39a1.269,1.269,0,0,0,1.8-1.792Z"
      transform="translate(0)"
      fill="#0098fe"
    />
  </svg>
);

const JumpIcon = () => (
  <svg
    width="700"
    height="700"
    version="1.1"
    viewBox="0 0 700 450"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="m546.7 224c-30.543 0-55.305 24.762-55.305 55.305 0 30.551 24.762 55.305 55.305 55.305 30.543 0 55.305-24.754 55.305-55.305 0-30.543-24.762-55.305-55.305-55.305m-196.7 0c-30.543 0-55.305 24.762-55.305 55.305 0 30.551 24.762 55.305 55.305 55.305 30.547 0 55.305-24.754 55.305-55.305 0-30.543-24.758-55.305-55.305-55.305m-141.38 55.305c0 30.551-24.762 55.305-55.312 55.305-30.543 0-55.305-24.754-55.305-55.305 0-30.543 24.762-55.305 55.305-55.305 30.551 0 55.312 24.762 55.312 55.305"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
export default function Pagination(props: PaginationProps) {
  const { locale } = useRouter();

  return (
    <StyledPagination
      {...props}
      locale={locale === 'ar' ? ar : en}
      nextIcon={ArrowIcon}
      prevIcon={ArrowIcon}
      jumpNextIcon={JumpIcon}
      jumpPrevIcon={JumpIcon}
    />
  );
}

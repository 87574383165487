import Footer from '@Components/Footer';
import PhoneTag from '@Components/LandingPage/PhoneTag';
import Navbar from '@Components/Navbar';
import dayjs from 'dayjs';
import { DefaultSeoProps, NextSeo, ProductJsonLd } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { Fragment, memo, ReactNode } from 'react';
import { Product } from 'types/Products';
import { Main } from './Layout.styled';

interface Props {
  children: ReactNode;
  seoData: DefaultSeoProps;
  noFollow?: boolean;
  hideNavbar?: boolean;
  product?: Product;
}

export default memo(function Layout({ children, seoData, noFollow, hideNavbar, product }: Props) {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      {product && (
        <Fragment>
          <Head>
            <meta property="product:price:currency" content="EGP" />
            <meta property="og:price:currency" content="EGP" />
            <meta property="product:availability" content="in stock" />
            <meta property="product:condition" content="new" />
            <meta property="product:price:amount" content={product.price?.toString()} />
            <meta property="og:price:amount" content={product.price?.toString()} />
            <meta property="product:retailer_item_id" content={product.id?.toString()} />
            <meta property="product:item_group_id" content={product.id?.toString()} />
            {Boolean(product.brand?.name) && <meta property="product:brand" content={product.brand.name} />}
          </Head>
          <ProductJsonLd
            productName={product.name}
            images={[...product.images.map((img) => img.url)]}
            description={product.description || ''}
            brand={product?.brand?.name}
            offers={[
              {
                price: product?.price?.toString(),
                priceCurrency: 'EGP',
                priceValidUntil: dayjs(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD'),
                itemCondition: 'https://schema.org/NewCondition',
                availability: 'https://schema.org/InStock',
                url: `https://www.yourparts.com/shop/${product.id}`,
                seller: {
                  name: product?.brand?.name || 'Yourparts',
                },
              },
            ]}
            sku={product.id.toString()}
          />
        </Fragment>
      )}
      <NextSeo
        titleTemplate={`${t('yourparts')} | %s`}
        description={t('yourparts-desc')}
        openGraph={{
          description: t('yourparts-desc'),
        }}
        {...seoData}
        {...(noFollow && { nofollow: true, noindex: true })}
      />
      {!hideNavbar && <Navbar />}
      <Main>{children}</Main>
      <PhoneTag />
      <Footer />
    </Fragment>
  );
});

import { BrandParameters } from 'types/Brand';
import { CategoryParameters } from 'types/Categories';
import { DefaultPagination } from 'types/common';
import { ProductParams } from 'types/Products';
import { ProductReviewParams } from 'types/Review';

export const K_AllProducts = (possibleKeys?: ProductParams & { locale?: string }) => [
  '/all-products',
  {
    ...possibleKeys,
  },
];
export const K_ProductDetails = (possibleKeys: { product_id: string; locale?: string }) => [
  `/all-products/${possibleKeys.product_id}`,
  {
    ...possibleKeys,
  },
];

export const K_Categories = (possibleKeys?: CategoryParameters & { locale?: string }) => [
  '/categories',
  {
    ...possibleKeys,
  },
];

export const K_OffersOfTheDay = (possibleKeys?: DefaultPagination & { locale?: string }) => [
  '/offers/offer-of-the-day',
  {
    ...possibleKeys,
  },
];

export const K_BrandsList = (possibleKeys?: BrandParameters & { locale?: string }) => [
  '/brands',
  {
    ...possibleKeys,
  },
];

export const K_AddressesList = (possibleKeys?: DefaultPagination & { locale?: string }) => [
  '/profile/addresses',
  {
    ...possibleKeys,
  },
];

export const K_CitiesList = (possibleKeys?: { locale?: string }) => [
  '/lookups/cities',
  {
    ...possibleKeys,
  },
];

export const K_PickupCities = (possibleKeys?: DefaultPagination & { locale?: string }) => [
  '/lookups/pick-up-cities',
  {
    ...possibleKeys,
  },
];

export const K_PickupPoints = (possibleKeys?: DefaultPagination & { locale?: string; district__id?: number }) => [
  '/lookups/pick-up-points',
  {
    ...possibleKeys,
  },
];

export const K_ProductRate = (possibleKeys: { product_id: string; locale?: string }) => [
  `/products/rating/${possibleKeys.product_id}`,
  {
    ...possibleKeys,
  },
];

export const K_ProductReview = (possibleKeys?: ProductReviewParams & { locale?: string }) => [
  '/products/review',
  {
    ...possibleKeys,
  },
];

export const K_Orders = (possibleKeys?: { locale?: string }) => [
  '/profile/orders',
  {
    ...possibleKeys,
  },
];

export const K_OrderDetails = (possibleKeys: { order_id: string; locale?: string }) => [
  `/profile/orders/${possibleKeys.order_id}`,
  {
    ...possibleKeys,
  },
];

export const K_MissingProducts = (possibleKeys?: { locale?: string }) => [
  '/profile/missing-products',
  {
    ...possibleKeys,
  },
];

export const K_MissingProductsDetails = (possibleKeys: { order_id: string; locale?: string }) => [
  `/profile/missing-products/${possibleKeys.order_id}`,
  {
    ...possibleKeys,
  },
];

export const K_Tyres = (possibleKeys?: DefaultPagination & { locale?: string }) => [
  '/lookups/tyres',
  {
    ...possibleKeys,
  },
];

export const K_Brands = (possibleKeys?: BrandParameters & { locale?: string }) => [
  `/lookups/brands`,
  {
    ...possibleKeys,
  },
];

export const K_Rims = (possibleKeys?: { locale?: string }) => [
  '/lookups/rim',
  {
    ...possibleKeys,
  },
];

export const K_Battery = (possibleKeys?: { locale?: string }) => [
  '/lookups/battery_power',
  {
    ...possibleKeys,
  },
];
export const K_OilKm = (possibleKeys?: { locale?: string }) => [
  '/lookups/km',
  {
    ...possibleKeys,
  },
];
export const K_OilViscosity = (possibleKeys?: { locale?: string }) => [
  '/lookups/oil_viscosity',
  {
    ...possibleKeys,
  },
];

export const K_ManufacturerCountry = (possibleKeys?: { locale?: string }) => [
  '/lookups/manufacturer_country',
  {
    ...possibleKeys,
  },
];

import { CommonButton } from '@Components/common/Button';
import { LOGIN_REDIRECT } from '@Constants/index';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { useCookie } from 'react-use';
import { Desc, ImageWrapper, Title, Wrapper } from './RequestCustomItem.styled';
import ReqIcon from '/public/custom-req.svg';
type Props = {};

export default function RequestCustomItem({}: Props) {
  const { userData } = useAuth();
  const { push } = useRouter();
  const { t } = useTranslation('common');
  const [, updateCookieRedirect] = useCookie(LOGIN_REDIRECT);

  const handleClick = () => {
    if (Boolean(userData)) {
      push('/profile/missing-products');
    } else {
      updateCookieRedirect(
        JSON.stringify({
          asPath: '/profile/missing-products',
          pathname: '/profile/missing-products',
        })
      );
      push('/auth/login');
    }
  };
  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={ReqIcon} alt="Request Custom Order" layout="fill" objectFit="contain" width={180} height={180} />
      </ImageWrapper>
      <Title>{t('request-title')}</Title>
      <Desc>{t('request-desc')}</Desc>
      <CommonButton variant="filled-red" onClick={handleClick}>
        {t('request-cta')}
      </CommonButton>
    </Wrapper>
  );
}
